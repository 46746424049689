function setEqualHeight() {
    $(".calendar-countdown,.weather-widget-io").matchHeight();
    $(".grid-menu-item-002").matchHeight();
    $(".kontakt-lokacija .grid-element .equal").matchHeight();
    $(".kontakt-in-lokacija .equal").matchHeight();
    $(".uvod .grid-element-content").matchHeight();
    $(".uvod .equal").matchHeight();
}

$(window).load(function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

/*
$(window).bind('scroll', function () {
    var skelBreakpoints = skel.vars.stateId,
        scroll_limit    = 50,
        margin_top;
    if (skelBreakpoints.search("/xsmall") > -1) {
        margin_top = 0 - Math.min($(window).scrollTop(),scroll_limit);
        $("#header-wrapper").css("margin-top",margin_top + "px");
    } else {
        $("#header-wrapper").css("margin-top","");
    }
});
*/
$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});
